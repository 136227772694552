import React, { useEffect } from "react";

const TawkToChat = () => {

    useEffect(() => {

        const script = document.createElement("script");
        script.src = "https://embed.tawk.to/674eec152480f5b4f5a71539/1ie64kdm8";
        script.async = true;
        script.charset = "UTF-8";
        script.setAttribute("crossorigin", "*");
        document.body.appendChild(script);

        script.onLoad = function () {
            script.setWidgetPosition("left", 10); // Position the widget to the left with a 10px 
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null; 
};

export default TawkToChat;
