import React, { useEffect, useState } from 'react';
import {
	Link,
	Typography,
	Button,
	TextField,
	Grid,
	Snackbar
} from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import HeaderNavBar from '../../components/HeaderNavBar';
import FooterNavBar from '../../components/FooterNavBar';
import "../Public/assets/css/style.css";
import "../Public/assets/css/colors/yellow.css";
import "../Public/assets/css/fonts/thicccboi.css";
import mujazBG from "../../img/bg.jpg";
import { makeStyles } from "tss-react/mui";

function Copyright(props) {
	return (
		<Typography variant="body2" color="text.secondary" {...props}>
			{'جميع الحقوق محفوظة © '}
			<Link color="inherit" href="https://mujaz.io/">
				mujaz.io
			</Link>
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const Home = () => {
	// const allowedSteps = [
	// 	{ value: 39, label: '50 ألف' },
	// 	{ value: 69, label: '100 ألف' },
	// 	{ value: 99, label: '200 ألف' },
	// 	{ value: 149, label: '350 ألف' },
	// 	{ value: 199, label: '500 ألف' },
	// 	{ value: 299, label: '750 ألف' },
	// 	{ value: 399, label: 'مليون' }
	// ];
	const [priceRange, setPriceRange] = useState(
		{
			cost: 39,
			emails: '50 ألف'

			// cost: 46,
			// emails: '50 ألف'
		}
		//allowedSteps
	);
	const [snackBar, setSnackBar] = useState({
		isOpen: false,
		message: '',
		background: '#FFC300'
	});
	const [email, setEmail] = useState('');
	const [value, setValue] = useState(21);
	// const allowedSteps = [21, 38, 72, 119, 159, 299];
	// const allowedSteps = [46, 63, 97, 144, 184, 324];
	const allowedSteps = [39, 69, 99, 149, 199, 299, 399];

	const rangeInput = (value) => {
		let closestStep = allowedSteps.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev));
		switch (closestStep) {
			// case 0:
			// 	setPriceRange({
			// 		cost: closestStep,
			// 		emails: '500'
			// 	});
			// 	break;
			case 39:
				setPriceRange({
					cost: closestStep,
					emails: '50 ألف'
				});
				break;
			case 69:
				setPriceRange({
					cost: closestStep,
					emails: '100 ألف'
				});
				break;
			case 99:
				setPriceRange({
					cost: closestStep,
					emails: '200 ألف'
				});
				break;
			case 149:
				setPriceRange({
					cost: closestStep,
					emails: '350 ألف'
				});
				break;
			case 199:
				setPriceRange({
					cost: closestStep,
					emails: '500 ألف'
				});
				break;
			case 299:
				setPriceRange({
					cost: closestStep,
					emails: '750 ألف'
				});
				break;
			case 399:
				setPriceRange({
					cost: closestStep,
					emails: 'مليون'
				});
				break;
		}
		// 	input.addEventListener("input", function (event) {
		// 		let value = Number(event.target.value);

		// 		input.value = closestStep;
		// 		switch (input.value) {
		// 			case 21:
		// 				setPriceRange({
		// 					cost: 21,
		// 					emails: '0-50K'
		// 				});
		// 				break;
		// 			case 38:
		// 				setPriceRange({
		// 					cost: 38,
		// 					emails: '50-100K'
		// 				});
		// 				break;
		// 			case 72:
		// 				setPriceRange({
		// 					cost: 72,
		// 					emails: '100-200K'
		// 				});
		// 				break;
		// 			case 119:
		// 				setPriceRange({
		// 					cost: 119,
		// 					emails: '200-350K'
		// 				});
		// 				break;
		// 			case 159:
		// 				setPriceRange({
		// 					cost: 159,
		// 					emails: '350-500K'
		// 				});
		// 				break;
		// 			case 299:
		// 				setPriceRange({
		// 					cost: 299,
		// 					emails: '500-1M'
		// 				});
		// 				break;
		// 		}
		// 		console.log(priceRange);
		// 		console.log(input.value)
		// 	});

	}
	const sendTestNewsLetter = async () => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (email == '') {
			setSnackBar({
				isOpen: true,
				background: '#F65858',
				message: 'إملأ البريد الإلكتروني'
			});
			return;
		}
		if (!emailRegex.test(email)) {
			setSnackBar({
				isOpen: true,
				background: '#F65858',
				message: 'صيغة عنوان البريد الإلكتروني غير صالحة',
			});
			return;
		}
		try {
			await fetch('https://us-central1-mujaz-platform.cloudfunctions.net/sendTestMujazNewsletter', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: email
				})
			})
				.then(response => {
					if (!response.ok) {
						return response.json().then(err => {
							throw new Error(err.error);
						});
					}
					return response.json();
				})
				.then(data => {
					// Reset form after successful submission
					setEmail('');
					setSnackBar({
						isOpen: true,
						background: '#51F9C3',
						message: 'تم إرسال نموذج من النشرة البريدية الخاصة بموجز'
					});
				})
				.catch(error => {
					console.log(error);
					setSnackBar({
						isOpen: true,
						background: '#F65858',
						message: error.message
					});
				})
		} catch (error) {
			setSnackBar({
				isOpen: true,
				background: '#F65858',
				message: error.message,
			});
		}

	}
	// const rangeInput = (value) => {
	// 	const closestStep = allowedSteps.reduce((prev, curr) =>
	// 		Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev
	// 	);

	// 	// Set the closest price and email range
	// 	setPriceRange([
	// 		{
	// 			value: closestStep.value,
	// 			label: closestStep.label
	// 		}
	// 	]);
	// };
	const useStyles = makeStyles()(
		() => ({
			main: {
				width: '100%',
				'@media only screen and (min-width: 1200px)': {
					width: '100%',
				},
			},
			btn_primary: {
				backgroundColor: '#FF6150',
				':hover': {
					backgroundColor: '#cc5e4e',
				}
			},
			btn_secondary: {
				border: 'none',
				color: '#FF6150',
				':hover': {
					border: 'none',
					color: '#cc5e4e',
				}
			},
			pinkyOrange: {
				backgroundColor: '#FF6150 !important',
				color: '#FFF'
			},
			yellowGold: {
				backgroundColor: '#FFCB00 !important',
				color: '#FFF'
			},
			containerSubscribe: {
				display: 'flex',
				alignItems: 'stretch',
				flexWrap: 'wrap',
				gap: '10px', // Space between items on mobile
				'@media (min-width: 768px)': {
					flexWrap: 'nowrap', // No wrapping on larger screens
					gap: '0',
				},
			},
			inputSubscribe: {
				flex: 1,
				width: '100%', // Full width on mobile
				borderRadius: '5px',
				'@media (min-width: 768px)': {
					width: 'auto', // Adjust for desktop
					marginRight: '-10px',
				},
			},
			buttonSubscribe: {
				width: '100%', // Full width on mobile
				height: 'auto',
				backgroundColor: '#54D2D2 !important',
				borderRadius: '5px !important',
				color: '#fff',
				'@media (min-width: 768px)': {
					backgroundColor: '54D2D2 !important',
					width: 'auto', // Adjust for desktop
					marginRight: '-5px !important',
					boxShadow: 'none !important'
				},
			},
			testNewsletter: {
				position: 'absolute',
				bottom: '-203px',
				width: '53%'
			}

		})
	);
	const { classes } = useStyles();

	useEffect(() => {



	}, [priceRange]);
	return (
		<div style={{ backgroundImage: `url(${mujazBG})`, backgroundSize: 'cover' }}>

			<Typography variant='body1' style={{ background: '#082e52', color: 'white', fontWeight: 500, textAlign: 'center' }}>نسخة تجريبية</Typography>

			<HeaderNavBar />



			<div className="offcanvas offcanvas-end text-inverse" id="offcanvas-info" data-bs-scroll="true">
				<div className="offcanvas-header">
					<h3 className="text-white fs-30 mb-0">Mujaz</h3>
					<button type="button" style={{ border: 'none', color: 'black', background: 'transparent' }} className="btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"><CloseRoundedIcon color="black" /></button>
				</div>
				<div className="offcanvas-body">
					<div className="widget mb-8">
						<p>موجز، منصّة إرسال بريد إلكتروني جماعي، تُمكّنك من إرسال وإدارة الرسائل البريدية والإخبارية والإعلانية باللغة العربية.</p>
					</div>
					<div className="widget mb-8" style={{ direction: 'ltr', textAlign: 'right' }}>
						<h4 className="widget-title text-white mb-3">معلومات الاتصال</h4>
						<address> تركيا <br /> إسطنبول  </address>
						<a href="mailto:hello@mujaz.io">hello@mujaz.io</a><br /> <span>+9 0 535 593 8150</span>
					</div>
					<div className="widget mb-8">
						<h4 className="widget-title text-white mb-3">اعرف المزيد</h4>
						<ul className="list-unstyled">
							<li><a href="/terms-policies#terms-conditions" className="link-body" style={{ fontSize: '0.9rem' }}> شروط الاستخدام </a></li>
							<li><a href="/terms-policies#privacy-policy" className="link-body" style={{ fontSize: '0.9rem' }}>سياسة الخصوصية</a></li>
							<li><a href="/terms-policies#return-policy" className="link-body" style={{ fontSize: '0.9rem' }}>سياسة الاسترجاع</a></li>
						</ul>
					</div>
					<div className="widget">
						<h4 className="widget-title text-white mb-3">تابعونا</h4>
						<nav className="nav social social-white">
							<a href="https://x.com/mujaz_io"><i className="uil uil-twitter"></i></a>
							<a href="https://www.facebook.com/mujazplatform?mibextid=ZbWKwL"><i className="uil uil-facebook-f"></i></a>
							<a href="https://www.instagram.com/mujaz.io/"><i className="uil uil-instagram"></i></a>
							<a href="https://www.linkedin.com/company/mujaz/"><i className="uil uil-linkedin"></i></a>
							{/* <a href="#"><i className="uil uil-dribbble"></i></a> */}
							{/* <a href="#"><i className="uil uil-youtube"></i></a> */}
						</nav>
					</div>
				</div>
			</div>


			<div className="content-wrapper rtl-mujaz">
				<section className="wrapper" id="home">
					<div className="container pt-10 pt-md-14 pb-8 text-center">
						<div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
							<div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
								<h2 className="display-1 mx-md-n5 mx-lg-0" style={{ textAlign: 'right' }}>أرسل الآن عبر موجز</h2>
								<TypeAnimation
									sequence={[
										'بسهولة', // Types 'One'
										1000, // Waits 1s
										'بسرعة', // Deletes 'One' and types 'Two'
										2000, // Waits 2s
										'بالعربية',
										3000,
									]}
									wrapper="h2"
									cursor={false}
									repeat={Infinity}
									style={{ fontSize: '2em', height: '50px', textAlign: 'right' }} />

								{/* <h1 className="display-1 mb-5 mx-md-n5 mx-lg-0"></h1> */}
								<p className="lead fs-lg mb-7">توفّر منصّة موجز مجموعة أدوات تُساعِدك على إنشاء وإدارات وتطوير نشراتك البريدية. قم بتطوير أعمالك مع موجز.</p>
								<p>
									<a href='#prices' className="btn rounded-pill-mujaz me-2 btn-mujaz-primary">جربها الآن</a></p>
							</div>
							<div className="col-lg-7" style={{ marginTop: 0 }}>
								<figure><img className="w-auto" src="img/landingpage/intro-mujaz.png" alt="" /></figure>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div className="content-wrapper rtl-mujaz mt-20">
				<section className={`wrapper ${classes.pinkyOrange}`} id="home">
					<div className="container pt-14 pt-md-14 pb-14 text-center">
						<div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
							<div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 text-center text-lg-start">
								<h2 className="display-3 mx-md-n5 mx-lg-0" style={{ textAlign: 'right', color: '#FFF' }}>أرسل موجز إلى بريدك الآن!</h2>
								<p className="lead fs-lg mb-7">إحصل على نموذج من النشرة البريدية الخاص بموجز</p>
								<form>
									<Grid container spacing={1} columns={12}>
										<Grid item xs={9}>
											<TextField
												className={classes.banerInput}
												size='small'
												fullWidth
												onChange={({ target: { value } }) => setEmail(value)}
												value={email}
												autoComplete="email"
												placeholder="البريد الإلكتروني"
											/>
										</Grid>
										<Grid item xs={2} alignItems="stretch" style={{ display: "flex" }}>
											<Button className={classes.yellowGold}
												variant="contained"
												size='small'
												fullWidth
												onClick={sendTestNewsLetter}
											>إرسال</Button>
										</Grid>
									</Grid>
								</form>
							</div>
							<div className="col-lg-6 d-none d-md-block" style={{ marginTop: 0, position: 'relative' }}>
								<img className={classes.testNewsletter} src="img/landingpage/test-newsletter.png" alt="" />
							</div>
						</div>
					</div>
				</section>
			</div>

			<div className="content-wrapper rtl-mujaz">
				<section className="wrapper" id='aboutus'>
					<div className="container py-14 py-md-16">
						<div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
							<div className="col-lg-6 position-relative order-lg-2">
								{/* <div className="shape bg-dot primary rellax w-16 h-20" data-rellax-speed="1" style={{ top: '3rem', left: '5.5rem' }}></div> */}
								<div className="overlap-grid overlap-grid-2">
									{/* <div className="item"> */}
									<figure className="rounded "><img src="./img/landingpage/whoisus.png" alt="" /></figure>
									{/* </div> */}
								</div>
							</div>
							<div className="col-lg-6">
								<img src="./assets/img/icons/lineal/megaphone.svg" className="svg-inject icon-svg icon-svg-md mb-4" alt="" />
								<h2 className="display-4 mb-3">من نحن</h2>
								<p className="lead fs-lg">موجز، منصّة إرسال بريد إلكتروني جماعي، تُمكّنك من إرسال وإدارة الرسائل البريدية والإخبارية والإعلانية باللغة العربية.</p>
								<p className="lead fs-lg">وذلك من خلال لوحة تحكّم متقدّمة وأدوات تحرير. ويمكّنك من إنشاء نشرة بريدية خلال فترة قصير. ومن أجل استهداف أكبر شريحة ممكنة تقدّم لك موجز أدوات خاصّة للتحليل والمراقبة.</p>
								<p className='lead fs-lg'>نحن هنا من أجل تعزيز المحتوى العربي، وإيصاله إلى الجمهور بطريقةٍ مختلفة وسهلة. كما وتصبح قراءة البريد الإلكتروني اليومي جزء من الروتين اليومي للبقاء على الإضطلاع بكل شيء يحدث من حولك.</p>
								<div className="row counter-wrapper gy-6 mt-6">
									<div className="col-md-6">
										<div className="progressbar semi-circle orange" data-value="99"></div>
										<h6 className="mb-0 text-center">سرعة الاستجابة والجهوزية</h6>
									</div>
									<div className="col-md-6">
										<div className="progressbar semi-circle orange" data-value="97"></div>
										<h6 className="mb-0 text-center">معدل التسليم</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="wrapper bg-light1 mt-14">
					<div className="container py-14 py-md-1 text-center">
						<div className="row">
							<div className="col-md-12 col-lg-12">
								<h3 className="display-4 mb-4 px-xl-10">بعض إحصائيات البريد الإلكتروني لعام 2023</h3>
							</div>

						</div>

						<div className="position-relative mb-4 mt-10">
							<div className="shape rounded-circle bg-soft-blue rellax w-16 h-16" data-rellax-speed="1" style={{ bottom: '-0.5rem', right: '-2.2rem', zIndex: 0 }}></div>
							<div className="shape bg-dot yellow rellax w-16 h-17" data-rellax-speed="1" style={{ top: '-0.5rem', left: '-2.5rem', zIndex: 0 }}></div>
							<div className="row gx-md-5 gy-5 text-center">
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>4.3</h1>
											<p className="mb-8">مليار مستخدم بريد إلكتروني نشط عالمياً</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>333.2</h1>
											<p className="mb-8">مليار  بريد إلكتروني يتم إرساله يومياً</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>99%</h1>
											<p className="mb-2">من مستخدمين يتحققون من بريدهم مرة واحد على الأقل</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="position-relative">
							<div className="row gx-md-5 gy-5 text-center">
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>21%</h1>
											<p className="mb-8">معدل متوسط لفتح البريد إلكتروني </p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>50%</h1>
											<p className="mb-2">معدل أعلى لفتح بريد إلكتروني يحتوي الموضوع في سطر واحد</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-4">
									<div className="card shadow-lg">
										<div className="card-body">
											<h1 className='counter' style={{ fontSize: '1.8rem' }}>42$</h1>
											<p className="mb-2">متوسط عائد على كل 1$ تنفقه على التسويق عبر البريد الإلكتروني</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</section>


				<section className="wrapper bg-light1 mt-14" id="specifics">
					<div className="container py-14 py-md-16">
						<div className="row text-center">
							<div className="col-md-12 col-lg-12">
								<h3 className="display-4 mb-9 px-xl-11">مميزات</h3>
							</div>

						</div>

						<div className="row gx-lg-8 gx-xl-12 gy-8 mt-0">

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-sm text-aqua ms-4" src="./img/icon/user-interface-02.png" srcSet="./img/icon/user-interface-02.png 2x" alt="" />
										{/* <img src="./img/icons/lineal/target.svg" srcSet="./img/icons/lineal/target.svg 2x" className="svg-inject icon-svg icon-svg-sm text-aqua me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">واجهة مستخدم سهلة</h4>
										<p className="mb-0">تُمكن واجهة المستخدم من الوصول السهل إلى جميع الأدوات التي تساعدك بإنشاء نشرتك البريدية </p>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-sm text-aqua ms-4" src="./img/icon/customizable-newsletter.png" srcSet="./img/icon/customizable-newsletter.png 2x" alt="" />
										{/* <img src="./assets/img/icons/lineal/medal.svg" className="svg-inject icon-svg icon-svg-sm text-yellow me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">نشرة بريدية قابلة للتخصيص</h4>
										<p className="mb-0">تخصيص شكل وألوان النشرة البريدية الخاصة بك لتكون جزء الهوية البصرية الخاصة بك</p>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-sm text-aqua ms-4" src="./img/icon/arabic-02.png" srcSet="./img/icon/arabic-02.png 2x" alt="" />
										{/* <img src="./assets/img/icons/lineal/clock-3.svg" className="svg-inject icon-svg icon-svg-sm text-red me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">دعم العربية</h4>
										<p className="mb-0">منصة كاملة باللغة العربية مخصصة للمستخدم من واجهة المستخدم حتى الدعم والتواصل</p>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-sm text-aqua ms-4" src="./img/icon/content-library.png" srcSet="./img/icon/content-library.png 2x" alt="" />
										{/* <img src="./assets/img/icons/lineal/check.svg" className="svg-inject icon-svg icon-svg-sm text-pink me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">مكتبة المحتوى</h4>
										<p className="mb-0">قم بحفظ المحتوى الخاص بكل في مكتبة واحد تُمكن من إرساله في وقت لاحق</p>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-md text-aqua ms-4" src="./img/icon/support-multiple-editors.png" srcSet="./img/icon/support-multiple-editors.png 2x" alt="" />
										{/* <img src="./assets/img/icons/lineal/shop-2.svg" className="svg-inject icon-svg icon-svg-sm text-green me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">دعم عدة محررين</h4>
										<p className="mb-0">بإمكانك إضافة أكثر من محرر وكاتب والعمل في نفس الوقت</p>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4">
								<div className="d-flex flex-row">
									<div>
										<img className="icon-svg icon-svg-sm text-aqua ms-4" src="./img/icon/statistics-and-analysis.png" srcSet="./img/icon/statistics-and-analysis.png 2x" alt="" />
										{/* <img src="./assets/img/icons/lineal/team.svg" className="svg-inject icon-svg icon-svg-sm text-purple me-4" alt="" /> */}
									</div>
									<div>
										<h4 className="mb-1">الإحصائيات والتحليل</h4>
										<p className="mb-0">أدوات تحليل ومراقبة للنشرة البريدية التي ترسلها مثل معدل الإرسال والاستلام والفتح</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="wrapper bg-light1 mt-14">
					<div className="container py-14 py-md-1">
						<div className="row text-center">
							<div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto text-center">
								<h3 className="display-4 mb-10 px-xxl-11">خدمات موجز</h3>
							</div>

						</div>
						<div className="row gx-lg-8 gx-xl-12 gy-8 mt-0">
							<div className="col-lg-6">
								<div className="row align-items-center justify-content-center">
									<div className='col-md-3 col-8'>
										<figure><img className="img-fluid-100" src="./img/landingpage/editorial-services.png" alt="" /></figure>
									</div>
									<div className='col-md-8'>
										{/* <div className="px-md-15 px-lg-3"> */}
										<h2 className='text-end'>التحرير والتدقيق اللغوي</h2>
										<p className="mb-2 text-justify" style={{ fontSize: '18px', paddingTop: '20px' }}>توفر خدمات التحرير لدينا طريقة خالية من المتاعب لتزويد المستخدمين بالمحتوى. نوفر لك الوقت والجهد والمال من خلال إزالة عبء التوظيف وإدارة فرق التحرير الداخلية والاستعانة بفريق متخصص للكتابة والتحرير لدى موجز وقم بتواصل على مدار الساعة لإنشاء أفضل محتوى.</p>
										{/* </div> */}
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row align-items-center justify-content-center">
									<div className='col-md-3 col-8'>
										<figure><img className="img-fluid-100" src="./img/landingpage/sponsor-program.png" alt="" /></figure>
									</div>
									<div className='col-md-8'>
										{/* <div className="px-md-15 px-lg-3"> */}
										<h2 className='text-end'>برنامج الرعاية</h2>
										<p className="mb-8 text-justify" style={{ fontSize: '18px', paddingTop: '20px' }}>يسمح لك برنامج الرعاية من كسب وزيادة إيراداتك عن طريق إضافة رعاة إلى رسالتك الإخبارية وفقًا لمنطقتك والجمهور المستهدف والصناعة ونوع المحتوى. نحن نعمل على تزويدك بالرعاة المناسبين بناءً على الإحصائيات والبيانات الخاصة بك.</p>
										{/* </div> */}
									</div>
								</div>

							</div>
						</div>
					</div>
				</section>



				<section className="wrapper bg-light1 mt-9" id='prices'>
					<div className="container py-14 py-md-1">
						<div className="row gy-6" style={{ display: 'flex', justifyContent: 'space-around' }}>
							{/* <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto text-center">
									<h3 className="display-4 mb-10 px-xxl-11">نماذج الأسعار</h3>
								</div> */}
							<div className="col-lg-12">
								<h2 className="display-4 mt-lg-18 mb-3 text-center">الباقات والأسعار</h2>
								<p className="lead fs-lg text-center">نقدّم نموذجين من الباقات المدفوعة،  لتختار المتناسب وحجم قائمتك البريدية بالإضافة إلى باقة مجانية شهرية حتى 1000 نشرة بريدية.</p>
								{/* <p>Enjoy a <a href="#" className="hover">free 30-day trial</a> and experience the full service. No credit card required!</p>
									<a href="#" className="btn btn-primary rounded-pill mt-2">See All Prices</a> */}
							</div>
							<div className="col-lg-12 pricing-wrapper">
								<div className="row gy-6 position-relative mt-5">
									<div className="shape bg-dot primary rellax w-16 h-18" data-rellax-speed="1" style={{ bottom: '-0.5rem', right: '-1.6rem' }}></div>
									<div className="shape rounded-circle bg-line red rellax w-18 h-18" data-rellax-speed="1" style={{ top: '-1rem', left: '-2rem' }}></div>

									<div className="col-md-4 popular">
										<div className="pricing card" style={{ maxHeight: '600px' }}>
											<div className="card-body pb-12 d-flex flex-column align-items-center justify-content-between" style={{ paddingTop: '2rem' }}>
												<h3 className="card-title text-center mt-2">الباقة المجانية</h3>

												<div className="prices text-dark mt-10 w-100">
													<div className="price price-show"><span className="price-value">مجانا</span>
														<span className="price-duration" style={{ marginBottom: '0.29rem' }}>1000 نشرة بريدية</span>
													</div>
												</div>
												<div className="prices text-dark">
													{/* <div className="price price-show mt-3"><AddCircleRoundedIcon style={{ fontSize: '25px', marginTop: '5px',color: '#FF6150' }} /><span className="price-currency">$</span><span className="price-value" style={{ fontSize: '1.6rem' }}>25</span> <span className="price-duration" style={{ marginBottom: '0.15rem' }}>شهر</span></div> */}
												</div>

												<ul className="icon-list bullet-bg bullet-soft-primary mt-7 mb-8" style={{ paddingRight: 0 }}>
													{/* <li><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150' }} /> <strong>0.70$</strong> لكل <strong>1000</strong> بريد إلكتروني</Typography></li> */}
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} />1000 نشرة بريدية شهريا</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} /> دعم التخصيص والتضمين في موقعك</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} /> دعم فني وتقيني كل أيام الاسبوع</Typography></li>
													<li><Typography variant='body2'>&nbsp;</Typography></li>
													<li><Typography variant='body2'>&nbsp;</Typography></li>
													<li><Typography variant='body2'>&nbsp;</Typography></li>
												</ul>
												<a href="/login" className="btn btn-primary rounded" style={{ background: '#FF6150' }}>اشترك الآن</a>
											</div>

										</div>
									</div>
									<div className="col-md-4 popular">
										<div className="pricing card" style={{ height: '600px', maxHeight: '630px' }}>
											<div className="card-body pb-12 d-flex flex-column align-items-center justify-content-between" style={{ paddingTop: '2rem' }}>
												<h3 className="card-title text-center mt-2">الباقة المرنة</h3>

												<div className="prices text-dark mt-10 w-100">
													<div className="price price-show"><span className="price-currency">$</span><span className="price-value">0.90</span>
														<span className="price-duration" style={{ marginBottom: '0.29rem' }}>1000 نشرة بريدية</span>
													</div>
												</div>
												<div className="prices text-dark w-100">
													<div className="price price-show mt-3"><AddCircleRoundedIcon style={{ fontSize: '25px', marginTop: '5px', color: '#FF6150', marginLeft: '10px' }} /><span className="price-currency">$</span><span className="price-value" style={{ fontSize: '1.6rem' }}>10</span> <span className="price-duration" style={{ marginBottom: '0.15rem' }}>شهر</span></div>
												</div>

												<ul className="icon-list bullet-bg bullet-soft-primary mt-7 mb-8" style={{ paddingRight: 0 }}>
													{/* <li><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150' }} /> <strong>0.70$</strong> لكل <strong>1000</strong> بريد إلكتروني</Typography></li> */}
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} /><strong>0.90$</strong> لكل 1000 نشرة   + <strong>10$</strong>/شهر</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} />حتى 50 ألف نشرة بريدية شهريا</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} />تخصيص وإضافات إعلان</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} />مساعد موجز الرقمي</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} /> دعم التخصيص والتضمين في موقعك</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} /> دعم فني وتقيني كل أيام الاسبوع</Typography></li>
												</ul>
												<a className="btn btn-primary rounded" style={{ background: '#FF6150' }}>قريبا</a>
											</div>

										</div>
									</div>
									<div className="col-md-4 popular">
										<div className="pricing card" style={{ maxHeight: '600px' }}>
											<div className="card-body pb-12 d-flex flex-column align-items-center justify-content-between" style={{ paddingTop: '2rem' }}>
												<h3 className="card-title text-center mt-2">الباقة الثابتة</h3>
												<div className="prices text-dark mt-10 w-100">
													<div className="price price-show"><span className="price-currency" style={{ paddingTop: '0.3rem' }}>$</span><span className="price-value">{priceRange.cost}</span>
														<span className="price-duration" style={{ marginBottom: '0.29rem', fontSize: '0.9rem' }}>{priceRange.emails} نشرة بريدية</span>
													</div>
												</div>


												<input id="rangeInput" type="range" value={priceRange.cost} onChange={(event) => rangeInput(event.target.value)} min={39} max={399} />
												{/* <TextField
													id="outlined-select-currency"
													select
													onChange={(e) => rangeInput(Number(e.target.value))}
												>
													{allowedSteps.map((option) => (
														<MenuItem key={option.value} value={option.value}>
															{option.label}
														</MenuItem>
													))}
												</TextField> */}
												<ul className="icon-list bullet-bg bullet-soft-primary mt-6 mb-8" style={{ paddingRight: 0 }}>
													<li><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} /> <strong>{priceRange.emails}</strong> نشرة بريدية</Typography></li>
													<li><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} />حتى 1 مليون نشرة بريدية شهريا</Typography></li>
													<li><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} />تخصيص وإضافات إعلان</Typography></li>
													<li><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} />مساعد موجز الرقمي</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} />دعم التخصيص والتضمين في موقعك</Typography></li>
													<li style={{ paddingLeft: 0 }}><Typography variant='body2'><CheckCircleIcon fontSize='14px' style={{ color: '#FF6150', marginLeft: '10px' }} />دعم فني وتقيني كل أيام الاسبوع</Typography></li>
												</ul>
												<a className="btn btn-primary rounded" style={{ background: '#FF6150' }}>قريبا</a>
											</div>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</section>

				{/* <section className="wrapper bg-light1 mt-9" id='subscribe'>
					<div class="container">
						<div class="row">
							<div class="col-md-12 mx-auto">
								<div class="card image-wrapper bg-full mb-n5" style={{background: 'rgb(8, 46, 82)'}} data-image-src="./assets/img/photos/bg3.jpg">
									<div class="card-body p-6 p-md-11">
										<div className='row'>
											<div className='col-md-6'>
												<div className='row'>
													<div className='col-md-3 px-5'>
													<figure><img className="w-auto" src="img/landingpage/icon.png" alt="" /></figure>
													</div>
													<div className='col-md-9 py-5 px-0'>
													<h3 className="display-6 mb-6 mb-lg-3 text-white">أنضم إلى القائمة البريدية</h3>
													<h5 className='mb-6 mb-lg-0 text-white'>أشترك في نشرتنا البريدية لتبقى على علم بكل ما هو جديد</h5>
													</div>
												</div>
											</div>
											<div className='col-md-6'></div>
										</div>
										
										
										<a href="#" class="btn btn-white rounded-pill mb-0 text-nowrap">Join Us</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
			</div>



			<section className="wrapper container mt-14">
				<div className="container bg-darkBlue rounded col-10 col-lg-8">
					<div className="d-flex flex-wrap flex-column flex-md-row align-items-center justify-content-between p-3 p-md-4 " >
						<div className="">
							<div className="d-flex flex-wrap flex-row  align-items-center justify-content-between"  >
								<div className="">
									<img src="./img/icon/MujazLogo.png" srcSet="./img/icon/MujazLogo.png 2x" alt="" width='60' />
								</div>
								<div className=" mt-3">
									<h3 className="card-title text-light">
										انضم الى القائمة البريدية
									</h3>
									<p className="text-white-50 fs-13">
										اشترك في نشرتنا البريدية لتبقى على علم بكل ما هو جديد
									</p>
								</div>
							</div>
						</div>
						<div className={classes.containerSubscribe}>
							<input
								type="text"
								className={classes.inputSubscribe}
								placeholder="بريد الكتروني"
							/>
							<Button
								variant="contained"
								size='small'
								className={classes.buttonSubscribe}
							>
								اشترك
							</Button>
						</div>
					</div>
				</div>
			</section>
			<FooterNavBar />


			<Snackbar
				ContentProps={{
					sx: {
						background: snackBar.background,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
					}
				}}
				open={snackBar.isOpen}
				autoHideDuration={6000}
				onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
				message={snackBar.message}
			/>

		</div>
	);
};

export default Home;
