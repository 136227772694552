import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Avatar,
    Paper,
    Stack,
    IconButton,
    TextField,
    Typography,
    Chip,
    Box,
    Link,
    Snackbar
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { Delete, Edit } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateIntroCard, generateKey, generateWrappedEmail, swapArrayItems } from '../../utils';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where, limit } from 'firebase/firestore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { connect } from 'react-redux';
import Footer from '../../components/Footer';
import AddEditSponsor from './SponsorAdd';
import { confirm } from "react-confirm-box";
import moment from 'moment/moment';
import logo from '../../img/pic-03.jpg';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import t1Image from '../../pages/Public/assets/img/avatars/t1.jpg';
import trash from '../../img/icons/trash.svg'
import SponsorAdd from './SponsorAdd';
import Chart from 'react-apexcharts'


const SponsorList = (props) => {
    const history = useHistory();
    const { company } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [sponsors, setSponsors] = useState([]);
    const [visibleSpon, setVisibleSpon] = useState(false)
    const [sponsorValues, setSponsorValues] = useState(false);
    const [ConfirmationDialogStatus, setConfirmationDialogStatus] = useState(false);
    const db = getFirestore();
   const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
        background: '#FFC300'

    });

    const useStyles = makeStyles()(
        () => ({
            confirmBox: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            submitBtn: {
                background: '#FF6150 !important',
                color: '#FFF !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            cancelBtn: {
                background: '#FFF !important',
                color: '#FF6150 !important',
                border: '1px solid #FF6150 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            btns: {
                color: '#FF6150'
            }

        })
    );

    const { classes } = useStyles();
    const [series, setSeries] = useState([
        {
            name: 'النشرات المرسلة',
            data: []
        },
        {
            name: 'النشرات المستلمة',
            data: []
        },
        {
            name: 'النشرات التي تم فتحها',
            data: []
        },
        {
            name: 'النقرات',
            data: []
        },
    ])
    const [graphOptions, setGraphOptions] = useState({
        series: series,
        options: {
            chart: {
                height: 100,
                type: 'area',
                toolbar: {
                    show: false,
                    tools: {
                        download: false
                    }
                }
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '14px',
                fontWeight: 400,
                itemMargin: {
                    horizontal: 15,
                    vertical: 30
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: []
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },

        },
    });
    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.confirmBox}>
                    <Grid item xs={1} sm={1} md={12}>
                        <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={4} direction="column" columns={{ xs: 1, sm: 1, md: 12, lg: 12 }} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item xs={1} sm={1} md={12} lg={12}>
                                    <Typography variant="body2" sx={{ fontSize: '25px', fontFamily: 'Tajawal' }}>{message}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12} lg={12}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={6} >
                                            <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={onCancel}> تراجع </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={6} >
                                            <Button variant="contained" className={classes.submitBtn} size="small" onClick={onConfirm}> تأكيد </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            );
        }
    };
    const getSponsors = async () => {
        const subRef = collection(db, "Sponsors");
        const q = query(subRef, where("company", "==", company));
        let data = await getDocs(q);
        data.forEach((doc) => {
            setSponsors(prev => [...prev, doc.data()]);

            // setSponsorVisible(sponsorVisible => [...sponsorVisible, false]);
        });
        if (!data.empty) {
            setSponsorValues(true)
        } else { setSponsorValues(false) }
        // setIsLoading(false);
        // setSponsorValues(data)
    };
    const handleDeleteSponsor = async (id) => {

        const result = await confirm("هل حقا تريد حذف هذا الراعي؟", options);
        if (result) {
            const q = query(collection(db, "Sponsors"), where("id", "==", id), limit(1));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const docRef = doc(db, "Sponsors", querySnapshot.docs[0].id);
                deleteDoc(docRef);
                setSponsors(sponsors.filter(sponsor => sponsor.id !== id));
                console.log(`Document with name "${id}" and id "${doc.id}" successfully deleted`);
            }
            setSnackBar({
                isOpen: true,
                background: '#51F9C3',
                message: 'تم حذف الراعي بنجاح',
            });
        }
        
    }


    useEffect(() => {
        getSponsors();
    }, [visibleSpon]);

    const CircularProgressWithLabel = (props) => {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate" {...props}
                    value={100}
                    size={90}
                    thickness={4}
                    sx={{ color: '#d3d3d3' }} />
                <CircularProgress
                    variant="determinate"
                    {...props}
                    size={90}
                    thickness={6}
                    sx={{
                        position: 'absolute', left: 0, color: '#FFCB00',
                        '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'round', // Make the end of the stroke rounded
                        },
                    }}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Typography variant="caption" component="div" color="textSecondary" align='center' sx={{ fontSize: '10px', }}>
                            <strong style={{ fontSize: '15px' }}>0$/0$</strong>
                        </Typography>
                    </div>
                </Box>
            </Box>
        );
    };

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 6,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#54D2D2' : '#54D2D2',
            direction: 'ltr',
        },
    }));


    return (


        <>
            <Grid container spacing={2} mt={1} justifyContent="center" >
                <Grid item sm={8} md={10} lg={10}>
                    <Grid container style={{ flexDirection: 'row-reverse', marginBottom: 15 }}>
                        <Grid item>

                            <Button size={"small"} style={{ backgroundColor: '#FF6150', borderRadius: '7px', marginLeft: '12px' }} variant="contained" onClick={() => { }}>
                                طلب معلن (قريبا)
                            </Button>
                            <Button size={"small"} style={{ backgroundColor: '#FF6150', borderRadius: '7px' }} variant="contained" onClick={() => {
                                setVisibleSpon(prev => !prev)
                            }
                            }>
                                إضافة إعلان جديد
                            </Button>
                        </Grid>
                    </Grid>

                    {visibleSpon && <SponsorAdd
                        onCloseIt={() => {
                            setTimeout(() => { setVisibleSpon(false); }, 500);
                            // setListUserVisible(true);
                            // setUserVisible(true);
                        }} />}
                    <Grid container spacing={4} mt={3}>
                        <Grid item sx={{ width: 'calc(35% - 0px)' }}>
                            <Paper elevation={0} style={{ padding: '10px 20px 10px 20px', minHeight: '170px' }}>
                                <Grid container spacing={3} columns={{ xs: 1, sm: 1, md: 10, }} mb={2}>
                                    <Grid item xs={1} sm={1} md={8.5}>
                                        <Typography variant="subtitle1" sx={{ fontSize: '12px', }}>الإعلانات النشطة </Typography>
                                    </Grid>
                                </Grid>

                                {sponsorValues ?
                                    (
                                        <>
                                            {sponsors.map((row, index) => {
                                                return <>
                                                    <Box sx={{
                                                        border: '1px solid',
                                                        borderRadius: '10px',
                                                        borderStyle: 'ridge',
                                                        marginTop: '10px'
                                                    }}>
                                                        <Grid container justifyContent="space-between" alignItems="center" sx={{ margin: '3px ' }} columns={{ xs: 1, sm: 1, md: 11, lg: 11 }}>
                                                            <Grid item md={1}>
                                                                <Avatar alt="Remy Sharp" src={row.logo} />
                                                            </Grid>
                                                            <Grid item md={4} lg={6} sx={{ marginLeft: { md: '0px', lg: '-24px' } }}>
                                                                <Grid container justifyContent="space-between" alignItems="center" mt={2} sx={{ marginTop: '6px' }}>
                                                                    <Grid item md={"auto"}>
                                                                        <Typography variant="body1" sx={{ fontSize: '9px', }}>{row.name}</Typography>
                                                                    </Grid>
                                                                    <Grid item md={"auto"}>
                                                                        <Typography variant="body1" sx={{ fontSize: '9px', }}>يوم 30/20</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <BorderLinearProgress variant="determinate" value={75} />
                                                            </Grid>
                                                            <Grid item md={2} mr={1} sx={{ paddingTop: '18px', display: 'flex', justifyContent: 'flex-end' }}>
                                                                {/* <IconButton size='small' aria-label="edit" sx={{ padding: '1px' }} >
                                                            <Edit style={{ fontSize: '0.8rem', }} />
                                                        </IconButton> */}
                                                                <IconButton size='small' aria-label="delete" onClick={() => handleDeleteSponsor(row.id)}>
                                                                    <img src={trash} alt="Trash Logo" style={{ width: '18px', height: '18px', }} />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>

                                                </>
                                            })}
                                            {/* <Box sx={{
                                                border: '1px solid',
                                                borderRadius: '10px',
                                                borderStyle: 'ridge',
                                                marginTop: '10px'
                                            }}>
                                                <Grid container justifyContent="space-between" alignItems="center" sx={{ margin: '3px ' }} columns={{ xs: 1, sm: 1, md: 11, lg: 11 }}>
                                                    <Grid item md={1}>
                                                        <Avatar alt="Remy Sharp" src={t1Image} />
                                                    </Grid>
                                                    <Grid item md={4} lg={6} sx={{ marginLeft: { md: '0px', lg: '-24px' } }}>
                                                        <Grid container justifyContent="space-between" alignItems="center" mt={2} sx={{ marginTop: '6px' }}>
                                                            <Grid item md={"auto"}>
                                                                <Typography variant="body1" sx={{ fontSize: '9px', }}>دار الفرقد</Typography>
                                                            </Grid>
                                                            <Grid item md={"auto"}>
                                                                <Typography variant="body1" sx={{ fontSize: '9px', }}>يوم 30/20</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <BorderLinearProgress variant="determinate" value={75} />
                                                    </Grid>
                                                    <Grid item md={2} mr={1} sx={{ paddingTop: '18px', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <IconButton size='small' aria-label="edit" sx={{ padding: '1px' }} >
                                                            <Edit style={{ fontSize: '0.8rem', }} />
                                                        </IconButton>
                                                        <IconButton size='small' aria-label="delete" >
                                                            <img src={trash} alt="Trash Logo" style={{ width: '18px', height: '18px', }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Box> */}

                                            {/* <Box sx={{
                                                border: '1px solid',
                                                borderRadius: '10px',
                                                borderStyle: 'ridge',
                                                marginTop: '10px'
                                            }}>
                                                <Grid container justifyContent="space-between" alignItems="center" sx={{ margin: '3px ' }} columns={{ xs: 1, sm: 1, md: 11, lg: 11 }}>
                                                    <Grid item md={1}>
                                                        <Avatar alt="Remy Sharp" src={t1Image} />
                                                    </Grid>
                                                    <Grid item md={4} lg={6} sx={{ marginLeft: { md: '0px', lg: '-24px' } }}>
                                                        <Grid container justifyContent="space-between" alignItems="center" mt={2} sx={{ marginTop: '6px' }}>
                                                            <Grid item md={"auto"}>
                                                                <Typography variant="body1" sx={{ fontSize: '9px', }}>دار الفرقد</Typography>
                                                            </Grid>
                                                            <Grid item md={"auto"}>
                                                                <Typography variant="body1" sx={{ fontSize: '9px', }}>يوم 30/20</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <BorderLinearProgress variant="determinate" value={75} />
                                                    </Grid>
                                                    <Grid item md={2} mr={1} sx={{ paddingTop: '18px', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <IconButton size='small' aria-label="edit" sx={{ padding: '1px' }} >
                                                            <Edit style={{ fontSize: '0.8rem', }} />
                                                        </IconButton>
                                                        <IconButton size='small' aria-label="delete" >
                                                            <img src={trash} alt="Trash Logo" style={{ width: '18px', height: '18px', }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Box> */}
                                        </>
                                    ) : (
                                        <Typography variant="subtitle1" sx={{ fontSize: '12px', }}> لا يوجد حاليا إعلانات نشطة</Typography>
                                    )}


                            </Paper>
                        </Grid>
                        <Grid item sx={{ width: 'calc(35% - 0px)' }}>
                            <Paper elevation={0} style={{ padding: '10px 20px 10px 20px' }}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={10.5}>
                                        <Typography variant="subtitle1" sx={{ fontSize: '12px', }}> الرصيد المدفوع </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '15px' }}>

                                        <Chart
                                            options={graphOptions.options}
                                            series={graphOptions.series}
                                            type="area"
                                            height={100}
                                        />



                                        {/* <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                            <Grid item xs={1} sm={1} md={12}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}> ألف مشترك</Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3} justifyContent="space-between" alignItems="center" style={{ paddingTop: '20px' }}>
                                            <Grid item xs={6} sm={6} md={"auto"} style={{ paddinRight: '20px' }}>
                                                <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', }} align='center'> إلغاء الإشتراك </Typography>
                                                <Typography variant="body2" color='#FF6150' style={{
                                                    fontSize: '0.75rem',
                                                    textAlign: 'left',
                                                    lineHeight: 1.4,
                                                }}>
                                                    <ArrowDownwardIcon fontSize="20px" />  %
                                                    <span style={{ fontSize: '10px', fontWeight: 'normal', color: 'grey', marginRight: '4px' }}>اخر اسبوع</span>

                                                </Typography>

                                            </Grid>

                                            <Grid item xs={6} sm={6} md={"auto"}>
                                                <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', marginLeft: '-10px', }} align='center'> مشترك جديد </Typography>
                                                <Typography variant="body2" color='#54D2D2' style={{
                                                    fontSize: '0.75rem',
                                                    textAlign: 'left',
                                                    lineHeight: 1.4,
                                                }}>

                                                    <ArrowUpwardIcon fontSize="20px" />%
                                                    <span style={{ fontSize: '10px', fontWeight: 'normal', color: 'grey', marginRight: '4px' }}>اخر اسبوع</span>
                                                </Typography>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>

                                </Grid>

                            </Paper>
                        </Grid >
                        <Grid item sx={{ width: 'calc(30% - 0px)' }}>
                            <Paper elevation={0} style={{ padding: '10px 20px 10px 20px' }}>
                                <Grid container spacing={5} columns={{ xs: 1, sm: 1, md: 10 }}>
                                    <Grid item xs={1} sm={1} md={8}>
                                        <Typography variant="subtitle1" sx={{ fontSize: '12px' }}>الرصيد من الاعلانات</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={12} mt={2} style={{ paddingTop: '9px' }}>

                                        <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 12 }}>
                                            <Grid item xs={1} sm={1} md={6}>
                                                <Grid container spacing={1} sx={{ justifyContent: "center", alignItems: "flex-start", }} columns={{ xs: 1, sm: 1, md: 12, lg: 12 }} direction="column">

                                                    <Grid item xs={1} sm={1} md={12} lg={12}>
                                                        <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>الرصيد الحالي</Typography>
                                                        <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>0$</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={12} lg={12}>
                                                        <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>رصيد السحب  </Typography>
                                                        <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>0$</Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={1} sm={1} md={6} textAlign="center">
                                                <CircularProgressWithLabel /*value={(80 / 150) * 100}*/ />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid >
                    </Grid>
                </Grid>
            </Grid >

            <Grid container spacing={2} mt={3} justifyContent="center" >
                <Grid item sm={8} md={10} lg={10}>
                    <Paper elevation={0} >
                        <Grid container sx={{ paddingLeft:'48px', paddingRight:'48px', mt: 3, paddingBottom: '24px' }} direction='column' justifyContent="center" >
                            <Grid item mb={3} >
                                <Typography variant="subtitle1" sx={{ fontSize: '14px', paddingTop: '25px' }}> الإعلانات</Typography>
                            </Grid>
                            <TableContainer component={Paper} style={{ boxShadow: 'none', }}>
                                <Table sx={{ minWidth: 500, }} aria-label="simple table">
                                    <TableHead sx={{
                                        background: '#F5F6FA',
                                        borderRadius: '10px',
                                        overflow: 'hidden',

                                    }}>
                                        <TableRow sx={{
                                            background: '#F5F6FA',
                                            '&:first-of-type': {
                                                borderTopLeftRadius: '10px',
                                                borderTopRightRadius: '10px',
                                            },
                                        }}>
                                            <TableCell sx={{borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', borderBottom: "none"}} align="center">
                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                    الشعار
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: "none"}} align="center">
                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                    العنوان

                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{  borderBottom: "none"}} align="center">
                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                    نوع العنوان

                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{  borderBottom: "none"}} align="center">
                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                    تاريخ البدء
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{  borderBottom: "none"}} align="center">
                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                    تاريخ الانتهاء
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderBottom: "none"}} align="center">
                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                    الاجراءات
                                                </Typography>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sponsors?.map((row, index) => {
                                            return (
                                               <>
                                                    <TableRow
                                                        key={row.id}
                                                        index={index}
                                                       //</React.Fragment> 
                                                       sx={{
                                                          // '&:last-child td, &:last-child th': { border: 0 },
                                                            // display: 'flex',
                                                            // justifyContent: 'space-between',
                                                    //    </TableBody> 
                                                    }}
                                                    >
                                                        <TableCell align="center" component="th" scope="row" sx={{ borderBottom: "none", display:'flex', justifyContent:'center' }} >
                                                            <Avatar alt="Remy Sharp" src={row.logo} />
                                                        </TableCell>
                                                        {/* <TableCell component="th" scope="row">
                                                        <Avatar alt="Remy Sharp" src={row.image} />
                                                    </TableCell> */}
                                                        <TableCell align="center" component="th" scope="row" sx={{ borderBottom: "none" }} >
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" sx={{ borderBottom: "none" }} >
                                                            {row.type}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" sx={{ borderBottom: "none" }} >
                                                            {
                                                                moment(new Date(row.dateFrom.seconds * 1000 + row.dateFrom.nanoseconds / 1000000)).format("YYYY-MM-DD")
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" sx={{ borderBottom: "none" }} >
                                                            {
                                                                moment(new Date(row.dateTo.seconds * 1000 + row.dateTo.nanoseconds / 1000000)).format("YYYY-MM-DD")
                                                            }
                                                        </TableCell>
                                                        {/* <TableCell component="th" scope="row">
                                                        {row.pattern.map((day, index) => (
                                                            <Chip key={index} label={day.label} size="small" />
                                                        ))}
                                                    </TableCell> */}
                                                        <TableCell align="center" sx={{ borderBottom: "none" }}>
                                                            <IconButton color="primary" size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteSponsor(row.id)}>
                                                                <img src={trash} alt="Trash Logo" style={{ width: '18px', height: '18px', }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                               </>
                                            )
                                            
                                        }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                ContentProps={{
                    sx: {
                        background: snackBar.background,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }
                }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />

        </>

    );
};
const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(SponsorList);
