import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
// import YouTubeNode from './nodes/YouTubeNode';

// import { $createYouTubeNode, YouTubeNode } from '../nodes/YouTubeNode';
function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}

const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    // YouTubeNode
  ]
};
export default function Editor() {
  return (
    <div style={{ minHeight: 250 }}>
      <LexicalComposer initialConfig={editorConfig}>
        <div className="editor-container">
          <ToolbarPlugin />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <TreeViewPlugin />
            <AutoFocusPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          </div>
        </div>
      </LexicalComposer>
    </div>
  );
}



//2nd
// import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
// import { CharacterLimitPlugin } from '@lexical/react/LexicalCharacterLimitPlugin';
// import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
// import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
// import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
// import { CollaborationPlugin } from '@lexical/react/LexicalCollaborationPlugin';
// import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
// import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
// import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
// import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
// import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
// import { ListPlugin } from '@lexical/react/LexicalListPlugin';
// import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
// import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
// import { SelectionAlwaysOnDisplay } from '@lexical/react/LexicalSelectionAlwaysOnDisplay';
// import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
// import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
// import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
// import React, { useEffect, useState } from 'react';
// import { CAN_USE_DOM } from 'shared/canUseDOM';


// import { createWebsocketProvider } from './collaboration';
// import { useSettings } from './context/SettingsContext';
// import { useSharedHistoryContext } from './context/SharedHistoryContext';
// import ActionsPlugin from './plugins/ActionsPlugin';
// import AutocompletePlugin from './plugins/AutocompletePlugin';
// import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
// import AutoLinkPlugin from './plugins/AutoLinkPlugin';
// import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
// import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
// import CollapsiblePlugin from './plugins/CollapsiblePlugin';
// import CommentPlugin from './plugins/CommentPlugin';
// import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
// import ContextMenuPlugin from './plugins/ContextMenuPlugin';
// import DragDropPaste from './plugins/DragDropPastePlugin';
// import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
// import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
// import EmojisPlugin from './plugins/EmojisPlugin';
// import EquationsPlugin from './plugins/EquationsPlugin';
// import ExcalidrawPlugin from './plugins/ExcalidrawPlugin';
// import FigmaPlugin from './plugins/FigmaPlugin';
// import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
// import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
// import ImagesPlugin from './plugins/ImagesPlugin';
// import InlineImagePlugin from './plugins/InlineImagePlugin';
// import KeywordsPlugin from './plugins/KeywordsPlugin';
// import { LayoutPlugin } from './plugins/LayoutPlugin/LayoutPlugin';
// import LinkPlugin from './plugins/LinkPlugin';
// import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
// import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin';
// import { MaxLengthPlugin } from './plugins/MaxLengthPlugin';
// import MentionsPlugin from './plugins/MentionsPlugin';
// import PageBreakPlugin from './plugins/PageBreakPlugin';
// import PollPlugin from './plugins/PollPlugin';
// import ShortcutsPlugin from './plugins/ShortcutsPlugin';
// import SpecialTextPlugin from './plugins/SpecialTextPlugin';
// import SpeechToTextPlugin from './plugins/SpeechToTextPlugin';
// import TabFocusPlugin from './plugins/TabFocusPlugin';
// import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
// import TableCellResizer from './plugins/TableCellResizer';
// import TableHoverActionsPlugin from './plugins/TableHoverActionsPlugin';
// import TableOfContentsPlugin from './plugins/TableOfContentsPlugin';
// import ToolbarPlugin from './plugins/ToolbarPlugin';
// import TreeViewPlugin from './plugins/TreeViewPlugin';
// import TwitterPlugin from './plugins/TwitterPlugin';
// import YouTubePlugin from './plugins/YouTubePlugin';
// import ContentEditable from './ui/ContentEditable';

// const skipCollaborationInit =
//   window.parent != null && window.parent.frames.right === window;

// export default function Editor() {
//   const { historyState } = useSharedHistoryContext();
//   const {
//     settings: {
//       isCollab,
//       isAutocomplete,
//       isMaxLength,
//       isCharLimit,
//       hasLinkAttributes,
//       isCharLimitUtf8,
//       isRichText,
//       showTreeView,
//       showTableOfContents,
//       shouldUseLexicalContextMenu,
//       shouldPreserveNewLinesInMarkdown,
//       tableCellMerge,
//       tableCellBackgroundColor,
//       tableHorizontalScroll,
//       shouldAllowHighlightingWithBrackets,
//       selectionAlwaysOnDisplay,
//     },
//   } = useSettings();
//   const isEditable = useLexicalEditable();
//   const placeholder = isCollab
//     ? 'Enter some collaborative rich text...'
//     : isRichText
//     ? 'Enter some rich text...'
//     : 'Enter some plain text...';
//   const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
//   const [isSmallWidthViewport, setIsSmallWidthViewport] = useState(false);
//   const [editor] = useLexicalComposerContext();
//   const [activeEditor, setActiveEditor] = useState(editor);
//   const [isLinkEditMode, setIsLinkEditMode] = useState(false);

//   const onRef = (_floatingAnchorElem) => {
//     if (_floatingAnchorElem !== null) {
//       setFloatingAnchorElem(_floatingAnchorElem);
//     }
//   };

//   useEffect(() => {
//     const updateViewPortWidth = () => {
//       const isNextSmallWidthViewport =
//         CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

//       if (isNextSmallWidthViewport !== isSmallWidthViewport) {
//         setIsSmallWidthViewport(isNextSmallWidthViewport);
//       }
//     };
//     updateViewPortWidth();
//     window.addEventListener('resize', updateViewPortWidth);

//     return () => {
//       window.removeEventListener('resize', updateViewPortWidth);
//     };
//   }, [isSmallWidthViewport]);

//   return (
//     <>
//       {isRichText && (
//         <ToolbarPlugin
//           editor={editor}
//           activeEditor={activeEditor}
//           setActiveEditor={setActiveEditor}
//           setIsLinkEditMode={setIsLinkEditMode}
//         />
//       )}
//       {isRichText && (
//         <ShortcutsPlugin
//           editor={activeEditor}
//           setIsLinkEditMode={setIsLinkEditMode}
//         />
//       )}
//       <div
//         className={`editor-container ${showTreeView ? 'tree-view' : ''} ${
//           !isRichText ? 'plain-text' : ''
//         }`}>
//         {isMaxLength && <MaxLengthPlugin maxLength={30} />}
//         <DragDropPaste />
//         <AutoFocusPlugin />
//         {selectionAlwaysOnDisplay && <SelectionAlwaysOnDisplay />}
//         <ClearEditorPlugin />
//         <ComponentPickerPlugin />
//         <EmojiPickerPlugin />
//         <AutoEmbedPlugin />
//         <MentionsPlugin />
//         <EmojisPlugin />
//         <HashtagPlugin />
//         <KeywordsPlugin />
//         <SpeechToTextPlugin />
//         <AutoLinkPlugin />
//         {/* <CommentPlugin
//           providerFactory={isCollab ? createWebsocketProvider : undefined}
//         /> */}
//         {isRichText ? (
//           <>
//             {/* {isCollab ? (
//               <CollaborationPlugin
//                 id="main"
//                 providerFactory={createWebsocketProvider}
//                 shouldBootstrap={!skipCollaborationInit}
//               />
//             ) : (
//               <HistoryPlugin externalHistoryState={historyState} />
//             )} */}
//             <RichTextPlugin
//               contentEditable={
//                 <div className="editor-scroller">
//                   <div className="editor" ref={onRef}>
//                     <ContentEditable placeholder={placeholder} />
//                   </div>
//                 </div>
//               }
//               ErrorBoundary={LexicalErrorBoundary}
//             />
//             <MarkdownShortcutPlugin />
//             <CodeHighlightPlugin />
//             <ListPlugin />
//             <CheckListPlugin />
//             <ListMaxIndentLevelPlugin maxDepth={7} />
//             <TablePlugin
//               hasCellMerge={tableCellMerge}
//               hasCellBackgroundColor={tableCellBackgroundColor}
//               hasHorizontalScroll={tableHorizontalScroll}
//             />
//             <TableCellResizer />
//             <ImagesPlugin />
//             <InlineImagePlugin />
//             <LinkPlugin hasLinkAttributes={hasLinkAttributes} />
//             <PollPlugin />
//             <TwitterPlugin />
//             <YouTubePlugin />
//             <FigmaPlugin />
//             <ClickableLinkPlugin disabled={isEditable} />
//             <HorizontalRulePlugin />
//             <EquationsPlugin />
//             <ExcalidrawPlugin />
//             <TabFocusPlugin />
//             <TabIndentationPlugin />
//             <CollapsiblePlugin />
//             <PageBreakPlugin />
//             <LayoutPlugin />
//             {floatingAnchorElem && !isSmallWidthViewport && (
//               <>
//                 <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
//                 <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
//                 <FloatingLinkEditorPlugin
//                   anchorElem={floatingAnchorElem}
//                   isLinkEditMode={isLinkEditMode}
//                   setIsLinkEditMode={setIsLinkEditMode}
//                 />
//                 <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
//               </>
//             )}
//             <TableCellActionMenuPlugin
//               anchorElem={floatingAnchorElem}
//               cellMerge={tableCellMerge}
//               cellBackgroundColor={tableCellBackgroundColor}
//               shouldIncludeCommentActions
//             />
//             <TableHoverActionsPlugin anchorElem={floatingAnchorElem} />
//             {showTableOfContents && <TableOfContentsPlugin />}
//             {shouldUseLexicalContextMenu && <ContextMenuPlugin />}
//           </>
//         ) : (
//           <>
//             <PlainTextPlugin
//               contentEditable={
//                 <div className="editor-scroller">
//                   <div className="editor">
//                     <ContentEditable placeholder={placeholder} />
//                   </div>
//                 </div>
//               }
//               ErrorBoundary={LexicalErrorBoundary}
//             />
//             <TabFocusPlugin />
//             <TabIndentationPlugin />
//           </>
//         )}
//       </div>
//       {showTreeView && <TreeViewPlugin />}
//       <ActionsPlugin />
// </>
// );
// }

