import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    IconButton,
    Switch,
    TextField,
    Typography,
    Select,
    Snackbar,
    MenuItem,
} from '@mui/material';
import speakeasy from "@levminer/speakeasy";
import QrCode from 'qrcode.react';
import AndroidStore from '../../img/icons/android-store.png';
import AppleStore from '../../img/icons/apple-store.png';
import { Delete, Edit } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { doc, addDoc, getDoc, updateDoc, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';
import { getAuth, updatePassword, EmailAuthProvider } from "firebase/auth";
import { makeStyles } from "tss-react/mui";
import { isEmpty } from 'lodash';
import ImageUploaderProfile from '../../components/ImageUploaderProfile';
import { connect } from 'react-redux';

const Profile = (props) => {
    const { company, data, loggedInUser } = props;
    const name = data?.personalProfile?.personalData;
    const [isLoading, setIsLoading] = useState(true);
    const [userName, setUserName] = useState(loggedInUser?.name);
    const [userEmail, setUserEmail] = useState(loggedInUser?.email);
    const [userPassword, setUserPassword] = useState('');
    const [userResponsibility, setUserResponsibility] = useState(loggedInUser?.responsibility);
    const [image, setImage] = useState(loggedInUser?.logo);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
        background: '#FFC300'
    });
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0px 3px 6px #ccc',
                background: '#fcfcfc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
                padding: '5px 40px'
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },


        })
    );
    const { classes } = useStyles();
    const [updatedUserData, setUpdatedUserData] = useState(loggedInUser);

    const handleUpdateUser = async () => {
        if (userPassword != '') {
            // const credential = EmailAuthProvider.credential(
            //     user.email,
            //     userPassword
            // );
            await updatePassword(user, userPassword).then(() => {
                // Update successful.
                console.log('password updated');
            }).catch((error) => {
                console.log(error);
                setSnackBar({
                    isOpen: true,
                    background: '#F65858',
                    message: error.message,
                });
                // An error ocurred
                // ...
            });
        }

        const docRef = collection(db, "Users");
        const q = query(docRef, where('uid', "==", user.uid));
        let data = await getDocs(q);
        data.forEach((v, i) => {
            let updatedUser = {
                _2fa: v.data()._2fa,
                company: v.data().company,
                email: v.data().email,
                logo: image,
                name: userName,
                responsibility: v.data().responsibility,
                uid: v.data().uid
            };
            setDoc(doc(db, "Users", v.id), updatedUser).then((_user) => {

                console.log('profile updated');
                setSnackBar({
                    isOpen: true,
                    background: '#51F9C3',
                    message: 'تم حفظ الحساب الشخصي بنجاح',
                });
            });
            props.setUser(updatedUser);
        })
        // verifiedQRCode();
    }


    //google auth 2fa
    const [secret, setSecret] = useState('');
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [userInput, setUserInput] = useState('');
    const [isValid, setIsValid] = useState(null);
    const [show2FA, setShow2FA] = useState(loggedInUser?._2fa?.enabled);
    const [verifiedQRCode, setVerifiedQRCode] = useState(loggedInUser?._2fa?.enabled);
    const generateOTP = () => {
        const { base32, otpauth_url } = speakeasy.generateSecret({
            name: loggedInUser.email,
            issuer: 'Mujaz',
            length: 12
        });
        setSecret(base32);
        const qrCodeUrl = otpauth_url;
        setQrCodeUrl(qrCodeUrl);
        setIsValid(null); // reset the validity state
    }

    const verifyScannedQRCode = async () => {
        const userRef = collection(db, "Users");
        const q = query(userRef, where("uid", "==", loggedInUser?.uid));
        const querySnapshot = await getDocs(q);
        const updatedData = { ...updatedUserData };
        if (show2FA) {
            // Update the enabled and secret values
            updatedData._2fa.enabled = true;
            updatedData._2fa.secret = secret;

            // Set the updated data to the state
            setUpdatedUserData(updatedData);
        } else {
            updatedData._2fa.enabled = false;
            updatedData._2fa.secret = '';
        }
        // If a matching document is found, update its fields
        if (!querySnapshot.empty) {
            await setDoc(doc(db, 'Users', querySnapshot.docs[0].id), updatedUserData).then(() => {
                setVerifiedQRCode(true);
                props.setUser(updatedUserData);
            }).catch((error) => {
                console.log(error);
                setSnackBar({
                    isOpen: true,
                    background: '#F65858',
                    message: error.message,
                });
            })
        } else {
            console.log('user not available in db');
        }
    }
    const handleSwitch2FAChange = (target, value) => {
        if (value) {
            setShow2FA(true);
            generateOTP();
        } else {
            setShow2FA(false);
            setQrCodeUrl('');
            setVerifiedQRCode(false);
        }
    }

    // const handleSaveButton = async () => {
    //     await Promise.all([
    //         handleUpdateUser(),
    //         verifyScannedQRCode()])
    // }
    //google auth 2fa
    return (
        <>
            {/* <Paper elevation={0} style={{ marginTop: '40px' }}> */}
            {/* <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 12 }} >
                <Grid item xs={12}>
                    <Paper elevation={0} style={{ padding: '10px' }}>
                        <Typography variant="body1">معلومات الحساب</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={0} style={{ padding: '45px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} alignContent='space-around' alignItems='center' >
                            <Grid item xs={1} sm={1} md={6}>
                                <TextField
                                    id="outlined-name"
                                    variant="outlined"
                                    size="small"
                                    label='الإسم'
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={1} sm={1} md={6}>
                                <TextField
                                    id="demo-simple-select"
                                    value={userResponsibility}
                                    variant="outlined"
                                    disabled
                                    size='small'
                                    label="المسؤولية"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                // onChange={(e) => setUserResponsibility(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={1} sm={1} md={6}>
                                <TextField
                                    id="outlined-name"
                                    value={userEmail}
                                    size='small'
                                    label="البريد اللإلكتروني"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                // onChange={(e) => setUserEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={1} sm={1} md={6}>
                                <TextField
                                    id="outlined-name"
                                    type='password'
                                    label="كلمة المرور"
                                    size='small'
                                    value={userPassword}
                                    onChange={(e) => setUserPassword(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} alignContent='space-around' alignItems='center' >
                            <Grid item xs={1} sm={1} md={6}>
                                <ImageUploaderProfile image={image} onChange={setImage} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={6}>
                                <Typography variant='body1'>{userName}</Typography>
                                <Typography variant='body1'>{userResponsibility}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container mt={2} mb={2}>
                <Grid item xs={4} className={classes.submitBtnGrid}>
                    <Grid container>
                        <Grid item xs={5}>
                            <Button
                                size='small'
                                color='primary'
                                // className={classes.submitBtn}
                                variant="contained"
                                onClick={e => handleUpdateUser()}
                            >
                                حفظ
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            <Grid container mt={4}>
                <Grid item xs={12}>
                    <Paper elevation={0} style={{ padding: '10px' }}>
                        <Typography variant="body1">أمن الحساب</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                <Grid item xs={12}>
                    <span>Google Authenticator</span><Switch
                        checked={show2FA}
                        onChange={handleSwitch2FAChange} />
                    {(show2FA && !verifiedQRCode) && <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item xs={12}>
                            <Paper elevation={0} style={{ padding: '20px' }}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                            <Grid item xs={12}>
                                                <Typography variant='body2'>امسح رمز الاستجابة السريعة التالي باستخدام Google Authenticator:</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                                    <Grid item xs={12}>
                                                        <Typography variant='body2'>يمكنك تحميل Google Authenticator عبر الروابط التالية:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" target="_blank">
                                                            <img src={AndroidStore} />
                                                        </a>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                                                            <img src={AppleStore} />
                                                        </a>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 1 }}>
                                            <Grid item xs={1} sm={1} md={1}>
                                                <Grid container columns={{ xs: 1, sm: 1, md: 1 }}>
                                                    <Grid item xs={1} style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <QrCode value={qrCodeUrl} size={200} />
                                                    </Grid>
                                                    <Grid item xs={1} style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Typography variant='caption'>{secret}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>

                        </Grid>
                    </Grid>}
                    <Grid container mt={2} spacing={1} columns={{ xs: 1, sm: 1, md: 1 }}>
                        <Grid item xs={1} sm={1} md={1}>
                            <Button size='small' variant='contained' onClick={verifyScannedQRCode}>حفظ</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}








            <Grid container mt={3.5} sx={{ justifyContent: 'center', alignItems: 'center' }} columns={{ sm: 12, md: 12, lg: 12 }}>
                <Grid item sm={12} md={10} lg={10}>
                    <Paper elevation={0} style={{ padding: '40px 80px 40px 80px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}> معلومات الملف الشخصي</Typography>
                        <Typography variant="body2" sx={{ fontSize: '12px' }}>  معلومات الحساب الشخصي في موجز يمكن تغيير كلمه المرور وتفعيل خصائص الحماية الاضافية</Typography>


                        <Grid container mt={5} sx={{ justifyContent: 'center' }} columns={{ sm: 12, md: 12, lg: 12 }}>
                            <Grid item container spacing={1} sm={12} md={8} lg={8}>
                                <Grid item lg={6}>
                                    <Typography variant="body2" sx={{ fontWeight: '500', fontSize: "12px" }}>الإسم الاول</Typography>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        variant="outlined"
                                        placeholder="الإسم الاول"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                fontSize: '0.7rem', // Smaller font size
                                            },
                                            '& .MuiInputLabel-root': {
                                                fontSize: '0.7rem', // Smaller label font size
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: '7px', // Adjust border radius
                                                },
                                            },
                                            paddingTop: "5px",

                                        }}
                                    />

                                    <Typography variant="body2" sx={{ fontWeight: '500', fontSize: "12px", marginTop: '15px' }}> البريد الالكتروني</Typography>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        value={userEmail}
                                        variant="outlined"
                                        placeholder="البريد الالكتروني"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                fontSize: '0.7rem', // Smaller font size
                                            },
                                            '& .MuiInputLabel-root': {
                                                fontSize: '0.7rem', // Smaller label font size
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: '7px', // Adjust border radius
                                                },
                                            },
                                            paddingTop: "5px",

                                        }}
                                    />

                                </Grid>
                                <Grid item lg={6}>
                                    <Typography variant="body2" sx={{ fontWeight: '500', fontSize: "12px" }}> الإسم الإخير</Typography>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        variant="outlined"
                                        placeholder="الإسم الإخير"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                fontSize: '0.7rem', // Smaller font size
                                            },
                                            '& .MuiInputLabel-root': {
                                                fontSize: '0.7rem', // Smaller label font size
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: '7px', // Adjust border radius
                                                },
                                            },
                                            paddingTop: "5px",

                                        }}
                                    />


                                    <Typography variant="body2" sx={{ fontWeight: '500', fontSize: "12px", marginTop: '15px' }}> المسؤولية</Typography>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        value={userResponsibility}
                                        variant="outlined"
                                        placeholder="المسؤولية"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                fontSize: '0.7rem', // Smaller font size
                                            },
                                            '& .MuiInputLabel-root': {
                                                fontSize: '0.7rem', // Smaller label font size
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: '7px', // Adjust border radius
                                                },
                                            },
                                            paddingTop: "5px",

                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <Grid item spacing={1} container sm={12} md={4} lg={4} direction='column' sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item>
                                    <Typography variant="body2" sx={{ fontWeight: '500', fontSize: "12px", }}> إضافة الصورة الشخصية</Typography>
                                </Grid>
                                <Grid item>
                                    <ImageUploaderProfile image={image} onChange={setImage} />
                                </Grid>
                            </Grid>

                            {/* <Grid container mt={2} mb={2} sx={{ justifyContent: "flex-end", alignItems: "center", }}>
                                <Grid item >
                                    <Button
                                        size='small'
                                        variant='contained'
                                        style={{ borderRadius: '7px', backgroundColor: '#FF6150' }}
                                        onClick={() => { handleUpdateUser() }}
                                    >
                                        حفظ
                                    </Button>
                                </Grid>
                            </Grid> */}

                        </Grid>

                        <Divider />

                        <Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: '40px' }}>  الأمان والحماية </Typography>
                        <Typography variant="body2" sx={{ fontSize: '12px' }}> يمكنك تغيير كلمة المرور وتفعيل خيار المصادقة الثنائية</Typography>

                        <Grid container spacing={1} mt={5} columns={{ lg: 12 }}>
                            <Grid item lg={4}>
                                <Typography variant="body2" sx={{ fontWeight: '500', fontSize: "12px" }}>كلمة المرور </Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    value={userPassword}
                                    onChange={(e) => setUserPassword(e.target.value)}
                                    variant="outlined"
                                    placeholder="كلمة المرور"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '0.7rem', // Smaller font size
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '0.7rem', // Smaller label font size
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '7px', // Adjust border radius
                                            },
                                        },
                                        paddingTop: "5px",

                                    }}
                                />
                            </Grid>
                            <Grid item lg={4}>
                                <Typography variant="body2" sx={{ fontWeight: '500', fontSize: "12px" }}> تأكيد كلمة المرور</Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    //value={userName}
                                    //onChange={}
                                    variant="outlined"
                                    placeholder="تأكيد كلمة المرور"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '0.7rem', // Smaller font size
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '0.7rem', // Smaller label font size
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '7px', // Adjust border radius
                                            },
                                        },
                                        paddingTop: "5px",

                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} mt={5} columns={{ lg: 12 }}>
                            <Grid item>
                                <Typography variant="body2" sx={{ fontWeight: '500', fontSize: "12px" }}>المصادقة الثنائية</Typography>
                                <Switch
                                    checked={show2FA}
                                    onChange={handleSwitch2FAChange}
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: '#54D2D2',
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                            backgroundColor: '#54D2D2',
                                        },
                                    }}
                                />

                                {(show2FA && !verifiedQRCode) && <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={12}>
                                        <Paper elevation={0} style={{ padding: '20px' }}>
                                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                                        <Grid item xs={12}>
                                                            <Typography variant='body2'>امسح رمز الاستجابة السريعة التالي باستخدام Google Authenticator:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant='body2'>يمكنك تحميل Google Authenticator عبر الروابط التالية:</Typography>
                                                                </Grid>
                                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" target="_blank">
                                                                        <img src={AndroidStore} />
                                                                    </a>
                                                                </Grid>
                                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                    <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                                                                        <img src={AppleStore} />
                                                                    </a>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 1 }}>
                                                        <Grid item xs={1} sm={1} md={1}>
                                                            <Grid container columns={{ xs: 1, sm: 1, md: 1 }}>
                                                                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                    <QrCode value={qrCodeUrl} size={200} />
                                                                </Grid>
                                                                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                    <Typography variant='caption'>{secret}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Grid>

                        <Grid container mt={2} sx={{ justifyContent: "flex-end", alignItems: "center", }}>
                            <Grid item >
                                <Button
                                    size='small'
                                    variant='contained'
                                    style={{ borderRadius: '7px', backgroundColor: '#FF6150' }}
                                    onClick={() => { handleUpdateUser() }}
                                >
                                    حفظ
                                </Button>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid >

            </Grid >


            <Snackbar
                ContentProps={{
                    sx: {
                        background: snackBar.background,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }
                }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>

    );
};
const mapStateToProps = (state) => {
    console.log(state);
    return state
}
const dispatchUser = (dispatch) => {
    return {
        setUser: (updatedUser) => { dispatch({ type: 'UPDATE_USER', value: updatedUser }) }
    }
}
export default connect(mapStateToProps, dispatchUser)(Profile);

