import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    IconButton,
    TextField,
    Typography,
    Snackbar,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { Delete, Edit } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateIntroCard, generateKey, generateWrappedEmail, swapArrayItems } from '../../utils';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';
import logo from '../../img/pic-03.jpg';
import Footer from '../../components/Footer';
import AddCompany from './components/AddCompany';
import { confirm } from "react-confirm-box";
import { connect } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getAuth, signOut, createUserWithEmailAndPassword } from "firebase/auth";


const Companies = (props) => {
    const ADMIN_APIKEY = '940BAC25A4E1A1E8E8E1FE502E41B8A623888FA863E370CBAA4D126079DE50FB4C3259C36EC4CBDFF8BA0DF6C14F6150';
    // const [smtp_connection, setSmtp_connection] = useState('');
    // const [apiKey, setApiKey] = useState('');
    let smtp_connection = '';
    let apiKey = '';
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [newCatVisible, setNewCatVisible] = useState(false);
    const [CatVisible, setCatVisible] = useState([]);
    const [ConfirmationDialogStatus, setConfirmationDialogStatus] = useState(false);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });

    // Initialize 2nd Firebase app
    const firebaseConfigSecondary = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSEGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };
    const secondaryApp = initializeApp(firebaseConfigSecondary, "secondary");
    const secondaryAuth = getAuth(secondaryApp);

    const db = getFirestore();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px 0px 30px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '2px 2px 10px #ccc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
                padding: '5px 25px',
                borderRadius: '30px',
                ':hover': {
                    background: '#FFF',
                    color: '#f63',
                }
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }

            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '50px 0px 0px 0px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '0px 3px 6px #00000026',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },
            confirmBtnCancel: {
                // boxShadow: '0px 3px 6px #00000026',
            },
            confirmBtnConfirm: {
                // boxShadow: '0px 3px 6px #00000026',
            },
            btnSecondary: {
                borderRadius: 30,
                backgroundColor: '#fff',
                color: '#ff6633',
                boxShadow: '0px 3px 6px #00000026',
                borderRadius: '30px',
                ':hover': {
                    backgroundColor: '#ff6633',
                    color: '#FFF',
                },
            }

        })
    );

    const { classes } = useStyles();

    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container className={classes.confirmContainer}>
                    <Grid item className={classes.confirmText}>
                        <Typography variant="h5">{message}</Typography>
                    </Grid>
                    <Grid item className={classes.confirmAction}>
                        <Button variant="contained" className={classes.confirmBtnConfirm} onClick={onCancel}> تراجع </Button>
                        <Button variant="contained" className={classes.confirmBtnCancel} onClick={onConfirm}> تأكيد </Button>
                    </Grid>



                </Grid>
            );
        }
    };
    const getDetails = async () => {
        const categoriesRef = collection(db, "Companies");
        const q = query(categoriesRef);
        let data = await getDocs(q);
        data.forEach((doc) => {
            console.log(doc.data());
            setItems(items => [...items, {
                id: doc.id,
                name: doc.data().personalProfile.companyData.company,
            }]);
            setCatVisible(prev => [...prev, false]);
        });
        setIsLoading(false);
    };
    const handleCatVisible = (index) => {
        let arr = [...CatVisible];
        arr[index] = !arr[index];
        setCatVisible(arr);
    };

    const handleAddCompany = (accountData) => {
        console.log(accountData);
        createSubAccountElastic(accountData)
    }

    const createSubAccountElastic = (accountData) => {
        let result = false;
        const SUBACCOUNT_URL = `https://api.elasticemail.com/v4/subaccounts?apikey=${ADMIN_APIKEY}`;
        const requestOptionsPOST_Subaccount = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                Email: accountData.personalProfile.personalData.username,
                Password: '123456'
            })
        };
        fetch(SUBACCOUNT_URL, requestOptionsPOST_Subaccount)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                generateAccountSettings(accountData)
            })
    }



    const generateAccountSettings = (accountData) => {
        let result = false;
        const APIKEY_URL = `https://api.elasticemail.com/v4/security/apikeys?apikey=${ADMIN_APIKEY}`;
        const SMTP_URL_ADD = `https://api.elasticemail.com/v4/security/smtp?apikey=${ADMIN_APIKEY}`;
        const SMTP_URL_DEL = `https://api.elasticemail.com/v4/security/smtp/${accountData.personalProfile.personalData.username}?apikey=${ADMIN_APIKEY}&subaccount=${accountData.personalProfile.personalData.username}`;
        const accessLevel = ["ViewAccount", "ViewSettings", "ViewReports", "ModifyAccount", "Export", "SendSmtp", "ModifySettings", "ModifyProfile", "Security", "SendHttp", "ModifySuppressions", "ViewSuppressions"];
        const requestOptionsPOST_APIKEY = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                Name: accountData.personalProfile.personalData.username,
                AccessLevel: accessLevel,
                Subaccount: accountData.personalProfile.personalData.username
            })
        }
        const requestOptionsPOST_SMTP_DEL = {
            method: 'DELETE',
            // headers: { 'Access-Control-Allow-Origin': '*' },
        }
        const requestOptionsPOST_SMTP_ADD = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                Name: accountData.personalProfile.personalData.username,
                Subaccount: accountData.personalProfile.personalData.username
            })
        }
        try {
            //create apikey for subaccount
            fetch(APIKEY_URL, requestOptionsPOST_APIKEY)
                .then(response => response.json())
                .then(apiData => {
                    //apikey token data
                    console.log(apiData);
                    apiKey = apiData.Token;
                    // setApiKey(apiData.Token);
                })
            //delete existing smtp token
            fetch(SMTP_URL_DEL,
                requestOptionsPOST_SMTP_DEL)

                .then(() => {
                    //create new smtp token
                    fetch(SMTP_URL_ADD, requestOptionsPOST_SMTP_ADD)
                        .then(__response => __response.json())
                        .then(smtpData => {
                            //smtp token data
                            console.log(smtpData);
                            smtp_connection = smtpData.Token
                            // setSmtp_connection(smtpData.Token);
                            createFirebaseManagerUser(accountData);
                        })
                }).catch(e => {
                    console.log(e);
                })

            result = true;
        }
        catch (e) {
            result = false;
            console.log(e);
        }
        return result;

    }

    const createFirebaseManagerUser = (accountData) => {
        // create auth user
        createUserWithEmailAndPassword(secondaryAuth, accountData.personalProfile.personalData.username, '123456')
            .then((userCredential) => {
                const user = userCredential.user;
                signOut(secondaryAuth); //signout the new user
                console.log(user);
                //create firestore user of type manager
                const docRef = addDoc(collection(db, "Users"), {
                    name: accountData.personalProfile.personalData.name,
                    email: accountData.personalProfile.personalData.username,
                    company: accountData.personalProfile.companyData.company,
                    responsibility: 'manager',
                    logo: '',
                    uid: user.uid
                }).then((d) => {
                    console.log(apiKey);
                    console.log(smtp_connection);
                    accountData.settings.senderSettings.smtp_connection = `smtp://${accountData.personalProfile.personalData.username}:${smtp_connection}@smtp.elasticemail.com:2525`;
                    accountData.settings.senderSettings.apiKey = apiKey;
                    const docRef = addDoc(collection(db, "Companies"), accountData).then((doc) => {
                        console.log('done');
                        setItems(items => [...items, {
                            id: doc.id,
                            name: accountData.personalProfile.companyData.company,
                        }]);
                        setNewCatVisible(prev => !prev);
                        clearAccountDataObject();
                    })
                })
            })
    }

    const clearAccountDataObject = () => {
        // accountData.personalProfile.personalData.email = '';
        // accountData.personalProfile.personalData.name = '';
        // accountData.personalProfile.personalData.username = '';

        // accountData.personalProfile.companyData.address = '';
        // accountData.personalProfile.companyData.city = '';
        // accountData.personalProfile.companyData.company = '';
        // accountData.personalProfile.companyData.country = '';
        // accountData.personalProfile.companyData.website = '';

        // accountData.settings.footer.copyrightText = '';
        // accountData.settings.footer.footerLogo = '';
        // accountData.settings.footer.slogon = '';
        // accountData.settings.footer.text = '';
        // accountData.settings.footer.unsubscribeText = '';
        // accountData.settings.footer.socialMedia.facebook = '';
        // accountData.settings.footer.socialMedia.instagram = '';
        // accountData.settings.footer.socialMedia.twitter = '';
        // accountData.settings.footer.socialMedia.youtube = '';
        // accountData.settings.senderSettings.defaultFrom = '';
        // accountData.settings.senderSettings.defaultReplyTo = '';
        // accountData.settings.senderSettings.email = '';
        // accountData.settings.senderSettings.name = '';
        // accountData.settings.senderSettings.toPreview = '';
        // accountData.settings.senderSettings.smtp_connection = '';
        // accountData.settings.senderSettings.apiKey = '';
        // accountData.settings.view.logo = '';
        // accountData.settings.view.primaryColor = '';
        // accountData.settings.view.secondaryColor = '';
    }

    useEffect(() => {
        getDetails();
    }, []);



    return (
        <>
            <Grid container style={{ flexDirection: 'row-reverse', marginBottom: 20 }}>
                <Grid item>
                    {/* <CircularProgress style={{ color: '#f63' }} /> */}
                    <Button variant="contained" className={classes.btnSecondary} onClick={() => setNewCatVisible(prev => !prev)}>
                        إضافة شركة جديدة
                    </Button>
                </Grid>
            </Grid>

            {newCatVisible && <AddCompany
                companyName=""
                id=""
                buttonName="إضافة"
                handleAddCompany={handleAddCompany} />}
            {items?.map((i, index) =>
                <div key={index}>
                    <Paper elevation={3} className={classes.paperList}>
                        <Grid item xs={9}>
                            <Typography variant="h5">
                                {i.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className={classes.editDeleteBtns}>
                            {/* <IconButton aria-label="delete" className={classes.btns}>
                                <Delete />
                            </IconButton> */}
                            {/* <IconButton aria-label="edit" className={classes.btns} onClick={() => handleCatVisible(index)}>
                                <Edit />
                            </IconButton> */}
                        </Grid>
                    </Paper>
                    {/* {CatVisible[index] && <AddEditCategory
                        name={i.name}
                        id={i.id}
                        index={index}
                        handleAddCategory={handleAddCategory}
                        buttonName="حفظ" />} */}
                </div>
            )}
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>

    );
};
const mapStateToProps = (state) => {
    console.log(state);
    return state
}


export default connect(mapStateToProps)(Companies);
// export default Categories;
