import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    IconButton,
    TextField,
    Typography,
    Select,
    MenuItem,
    sx,
    Box,
    Snackbar
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { doc, addDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';

import { makeStyles } from "tss-react/mui";
import { isEmpty } from 'lodash';
import ImageUploaderProfile from './ImageUploaderProfile';
import Drawer from '@mui/material/Drawer';
import userManagementIcon from "../img/icons/User management V2.png"


const AddEditUser = ({ buttonName, name, email, responsibility, logo, id, uid, index, handleAddUser, handleCancelAddUser, onCloseIt }) => {
    const [userId, setUserId] = useState(id);
    const [userUid, setUserUid] = useState(uid);
    const [isLoading, setIsLoading] = useState(true);
    const [userName, setUserName] = useState(name);
    const [userEmail, setUserEmail] = useState(email);
    const [userPassword, setUserPassword] = useState('');
    const [userResponsibility, setUserResponsibility] = useState(responsibility);
    const [image, setImage] = useState(logo);
    const [open, setOpen] = useState(true)
    // const [visible, setVisible] = useState(false);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
        background: '#FFC300'
    });
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                //color: '#FF6150',
                backgroundColor: '#FF6150',
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px',
                display: 'flex',
                flexDirection: 'column',
                // boxShadow: '0px 3px 6px #ccc',
                // background: '#fcfcfc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
                marginTop: '0px',
            },
            gridContainer: {
                width: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            },
            item: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },


        })
    );

    const { classes } = useStyles();
    const db = getFirestore();
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
        onCloseIt();
    };

    const handleEmptyFields = () => {

        if (
            userName.trim() === "" ||
            userResponsibility.trim() === "" ||
            userEmail.trim() === "" ||
            userPassword.trim() === ""
        ){
            setSnackBar({
                isOpen: true,
                background: '#FFC300',
                message: 'الرجاء تعبئة جميع الحقول المطلوبة',
            });
            return;
        }
        handleAddUser(userName, userResponsibility, userEmail, userPassword, image, id, uid, index)

    }

    return (
        <>

            <Drawer open={open} anchor={"right"} onClose={toggleDrawer(false)} className={classes.gridContainer}>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '500px',
                    }}
                >
                    {/* <Grid container spacing={2} mb={2} columns={{ xs: 1, sm: 1, md: 12 }} style={{ marginRight: 0, width: '100%' }}>
                    <Paper elevation={0} className={classes.textPaper}>
                        <Grid container spacing={4} style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}>
                            <Grid item xs={7}>
                                <Grid item className={classes.addCatForm}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label='الإسم'
                                        id="outlined-name"
                                        margin="dense"
                                        index={index}
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item className={classes.addCatForm} mt={2}>
                                    <Select
                                        fullWidth
                                        size='small'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        margin="dense"
                                        index={index}
                                        value={userResponsibility}
                                        label="المسؤولية"
                                        onChange={(e) => setUserResponsibility(e.target.value)}
                                    >
                                        <MenuItem value='manager'>manager</MenuItem>
                                        <MenuItem value='editor'>Editor</MenuItem>
                                    </Select>
                                </Grid>
                                {uid == '' && <Grid item className={classes.addCatForm} mt={2}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label='البريد اللإلكتروني'
                                        id="outlined-name"
                                        margin="dense"
                                        index={index}
                                        value={userEmail}
                                        onChange={(e) => setUserEmail(e.target.value)}
                                    />
                                </Grid>}
                                {id == '' &&
                                    <Grid item className={classes.addCatForm} mt={2}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label='كلمة المرور'
                                            id="outlined-name"
                                            margin="dense"
                                            index={index}
                                            value={userPassword}
                                            onChange={(e) => setUserPassword(e.target.value)}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 1 }}>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <ImageUploaderProfile image={image} onChange={setImage} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}>
                                        <p style={{ fontWeight: 'bold', textAlign: 'center', lineHeight: '0.5' }}>{userName}</p>
                                        <p style={{ color: '#eee', textAlign: 'center', lineHeight: '0.5' }}>{userResponsibility}</p>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid >
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            size='small'
                            variant="outlined"
                            onClick={handleCancelAddUser} >
                            رجوع
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size='small'
                            variant="contained"
                            onClick={e => handleAddUser(userName, userResponsibility, userEmail, userPassword, image, id, uid, index)} >
                            {buttonName}
                        </Button>
                    </Grid>
                </Grid> */}
                    <Grid container spacing={2} style={{ flexDirection: 'column', width: '400px' }} >
                        <Grid item className={classes.item}>
                            <img src={userManagementIcon} width={100} />
                        </Grid>

                        <Grid item xs={12} lg={11}>
                            <Grid item >
                                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                    اسم المستخدم
                                </Typography>
                            </Grid>
                            <Grid item sx={{ marginTop: '-6px' }} className={classes.addCatForm}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    placeholder='اسم المستخدم'
                                    id="outlined-name"
                                    margin="dense"
                                    index={index}
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    sx={{
                                        mb: 4,
                                        '& .MuiInputBase-root': {
                                            fontSize: '0.7rem', // Smaller font size
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '0.7rem', // Smaller label font size
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '7px', // Adjust border radius
                                            },
                                        },
                                    }}
                                />
                            </Grid>

                            {userUid == '' &&
                                <>
                                    <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                        البريد الالكتروني
                                    </Typography>

                                    <Grid item sx={{ marginTop: '-6px' }} className={classes.addCatForm}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            placeholder='البريد اللإلكتروني'
                                            id="outlined-name"
                                            margin="dense"
                                            index={index}
                                            value={userEmail}
                                            onChange={(e) => setUserEmail(e.target.value)}
                                            sx={{
                                                mb: 4,
                                                '& .MuiInputBase-root': {
                                                    fontSize: '0.7rem', // Smaller font size
                                                },
                                                '& .MuiInputLabel-root': {
                                                    fontSize: '0.7rem', // Smaller label font size
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderRadius: '7px', // Adjust border radius
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item >
                                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                    الصلاحيات
                                </Typography>
                            </Grid>
                            <Grid item sx={{ marginTop: '0px' }} className={classes.addCatForm} mt={2}>
                                <Select
                                    fullWidth
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    margin="dense"
                                    index={index}
                                    value={userResponsibility}
                                    placeholder="الصلاحيات"
                                    onChange={(e) => setUserResponsibility(e.target.value)}
                                    sx={{
                                        mb: 4,
                                        height: '37px',
                                        borderRadius: '7px',
                                    }}
                                >
                                    <MenuItem value='manager'>مدير</MenuItem>
                                    <MenuItem value='editor'>محرر</MenuItem>
                                </Select>
                            </Grid>

                            {userId == '' &&
                                <>
                                    <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                        كلمة المرور
                                    </Typography>
                                    <Grid item sx={{ marginTop: '-6px' }} className={classes.addCatForm}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            placeholder='كلمة المرور'
                                            id="outlined-name"
                                            margin="dense"
                                            index={index}
                                            value={userPassword}
                                            onChange={(e) => setUserPassword(e.target.value)}
                                            sx={{
                                                mb: 4,
                                                '& .MuiInputBase-root': {
                                                    fontSize: '0.7rem', // Smaller font size
                                                },
                                                '& .MuiInputLabel-root': {
                                                    fontSize: '0.7rem', // Smaller label font size
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderRadius: '7px', // Adjust border radius
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                        <Grid item xs={12} lg={11} container style={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <Grid item >
                                {/* <Button
                                    size='small'
                                    style={{ backgroundColor: '#FF6150', borderRadius: '7px' }}
                                    variant="contained"
                                    onClick={e => handleAddUser(userName, userResponsibility, userEmail, userPassword, image, id, uid, index)}
                                >إضافة</Button> */}
                                <Button
                                    size='small'
                                    variant="contained"
                                    style={{ backgroundColor: '#FF6150', borderRadius: '7px' }}
                                    onClick={
                                        // e => handleAddUser(userName, userResponsibility, userEmail, userPassword, image, id, uid, index)
                                        e => handleEmptyFields()
                                        } >
                                    {buttonName}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>


                </Box>

            </Drawer>
            <Snackbar
                ContentProps={{
                    sx: {
                        background: snackBar.background,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }
                }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />

        </>

    );
};

export default AddEditUser;


